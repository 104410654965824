import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../components/layout/layout"
import Title from "../components/Title/Title"
import Form from "../components/Form/Form"
import * as ContactStyles from "./contact.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"

function Contact() {
    return (
        <Layout pageTitle="Contact">
            <Helmet>
                <meta name="description" content="Get in touch with any questions you may have or to arrange an appointment." />
            </Helmet>
            <section>
                <Title>
                    <h1>Contact</h1>
                    <p>Get in touch with any questions you may have or to arrange an appointment.</p>
                </Title>
            </section>
            <section>
                <div className={ContactStyles.container}>
                    <div className={ContactStyles.detailsContainer}>
                        <h3>By appointment only</h3>
                        <p>
                            60 Main Street<br />
                            Kibworth Harcourt<br />
                            Leicester<br />
                            LE8 0NQ
                        </p>
                        <p>what3words: <a href="https://w3w.co/mermaids.miles.regulator" rel="noopener noreferrer" target="_blank">mermaids.miles.regulator</a></p>
                        <h3>Call, WhatsApp or email</h3>
                        <a className={ContactStyles.contactDetail} href="tel:+447971295152"><span className={ContactStyles.icon}><FontAwesomeIcon icon={faPhone} /></span>07971 295 152</a>
                        <a className={ContactStyles.contactDetail} href="mailto: christian@consultingoptometrist.co.uk"><span className={ContactStyles.icon}><FontAwesomeIcon icon={faEnvelope} /></span>christian@consultingoptometrist.co.uk</a>
                    </div>
                    <div>
                        <h3>Send a message</h3>
                        <Form />
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contact